import {
  FaSnapchat,
  FaInstagram,
  FaWhatsapp,
  FaTiktok,
  FaPhoneAlt,
} from 'react-icons/fa'
import ReciveFromStore from '../components/ReciveFromStore'
import TableNumber from '../components/TableNumber'
import Transport from '../components/Transport'

export const sideNavData = [
  {
    id: 1,
    text: 'سناب شات',
    icon: FaSnapchat,
    href: 'https://www.snapchat.com/add/casa.cream',
  },
  {
    id: 2,
    text: 'تيك توك',
    icon: FaTiktok,
    href: 'https://www.tiktok.com/@casa.cream.sa',
  },
  {
    id: 3,
    text: 'إنستجرام',
    icon: FaInstagram,
    href: 'https://www.instagram.com/casa.cream.sa/',
  },
  {
    id: 4,
    text: 'واتساب',
    icon: FaWhatsapp,
    href: 'https://api.whatsapp.com/send/?phone=966501797470',
  },
  { id: 5, text: 'اتصل بنا', icon: FaPhoneAlt, href: 'tel:0501797470' },
]
export const restaurants = [
  {
    id: 1,
    name: 'كازا كريم',
    distance: 5,
    addrress: ' شارع بديع الزمان ، حي الزهرة ، الرياض , المملكة العربية السعودية',
    open: true,
    open24h: true,
    searchQuery: 'شارع بديع الزمان , حي الزهرة , الرياض',
    coords: { lat: 24.5846936, lng: 46.6430892 },
  },
]
export const data = [
  {
    id: 1,
    title: 'القهوة الساخنة',
    image: '../../../img/list/coffe-cup.png',
    selected: false,
    items: [
      {
        id: 1,
        title: 'لاتيه',
        description: '',
        image: '../../img/food/f27.jpg',
        price: 12,
        calories: '151',
        selected: false,
        size: {
          small: 12,
          large: 15,
       },
      },
      {
        id: 2,
        title: 'سبانش لاتيه',
        description: '',
        image: '../../img/food/f27.jpg',
        price: 13,
        calories: '209',
        selected: false,
        size: {
          small: 13,
          large: 16,
       },
      },
      {
        id: 3,
        title: 'بستاشيو لاتيه',
        description: '',
        image: '../../img/food/f27.jpg',
        price: 15,
        calories: '180',
        selected: false,
        size: {
          small: 15,
          large: 17,
       },
      },
      {
        id: 4,
        title: 'كراميل لاتيه',
        description: '',
        image: '../../img/food/f27.jpg',
        price: 15,
        calories: '206',
        selected: false,
        size: {
          small: 15,
          large: 17,
       },
      },
      {
        id: 5,
        title: 'كابتشينو',
        description: '',
        image: '../../img/food/0ي.jpg',
        price: 13,
        calories: '144',
        selected: false,
        size: {
          small: 13,
          large: 16,
       },
      },
      {
        id: 6,
        title: 'كابتشينو كراميل',
        description: '',
        image: '../../img/food/0ي.jpg',
        price: 13,
        calories: '250',
        selected: false,
        size: {
          small: 13,
          large: 16,
       },
      },
      {
        id: 7,
        title: ' هوت شوكلت',
        description: '',
        image: '../../img/food/59.jpg',
        price: 15,
        calories: '300',
        selected: false,
        size: {
          small: 15,
          large: 18,
       },
      },
      {
        id: 70,
        title: 'موكا',
        description: '',
        image: '../../img/food/5989.jpg',
        price: 15,
        calories: '215',
        selected: false,
        size: {
          small: 15,
          large: 18,
       },
      },
      {
        id: 8,
        title: ' وايت موكا',
        description: '',
        image: '../../img/food/5989.jpg',
        price: 15,
        calories: '215',
        selected: false,
        size: {
          small: 15,
          large: 18,
       },
      },
      {
        id: 9,
        title: 'فلات وايت',
        description: '',
        image: '../../img/food/f27.jpg',
        price: 15,
        calories: 131,
        selected: true,
      },
      {
        id: 10,
        title: 'كورتادو',
        description: '',
        image: '../../img/food/f27.jpg',
        price: 12,
        calories: 162,
        selected: true,
      },
      {
        id: 11,
        title: 'امريكانو',
        description: '',
        image: '../../img/food/191.jpg',
        price: '9',
        calories: 12,
        selected: false,
        size: {
          small: 9,
          large: 12,
       },
      },
      {
        id: 12,
        title: 'اسبريسو',
        description: '',
        image: '../../img/food/7883.jpg',
        price: '9',
        calories: '4',
        selected: false,
        size: {
          small: 9,
          large: 12,
       },
      },
      {
        id: 13,
        title: 'قهوة فرنسي',
        description: '',
        image: '../../img/food/59.jpg',
        price: 12,
        calories: '7',
        selected: false,
        size: {
          small: 12,
          large: 14,
       },
      },
      {
        id: 14,
        title: 'قهوة ايطالي',
        description: '',
        image: '../../img/food/59.jpg',
        price: 12,
        calories: '7',
        selected: false,
        size: {
          small: 12,
          large: 14,
       },
      },
      {
        id: 15,
        title: 'قهوة تركي',
        description: '',
        image: '../../img/food/2513.jpg',
        price: 12,
        calories: '5',
        selected: false,
        size: {
          small: 12,
          large: 14,
       },
      },
      {
        id: 16,
        title: 'قهوة مغربية',
        description: '',
        image: '../../img/food/b01e.jpg',
        price: 15,
        calories: 144,
        selected: true,
      },
    ],
  },
  {
    id: 2,
    title: 'القهوة الباردة',
    image: '../../../img/list/bard.png',
    selected: true,
    items: [
      {
        id: 17,
        title: 'ايس لاتيه',
        description: '',
        image: '../../img/food/صbe.jpg',
        price: 13,
        calories: '151',
        selected: false,
        size: {
          small: 13,
          large: 18,
       },
      },
      {
        id: 18,
        title: 'ايس سبانش لاتيه',
        description: '',
        image: '../../img/food/صbe.jpg',
        price: 14,
        calories: '206',
        selected: false,
        size: {
          small: 14,
          large: 17,
       },
      },
      {
        id: 19,
        title: 'ايس فانيلا لاتيه',
        description: '',
        image: '../../img/food/صbe.jpg',
        price: 13,
        calories: '227',
        selected: false,
        size: {
          small: 13,
          large: 18,
       },
      },
      {
        id: 20,
        title: 'ايس بستاشيو لاتيه',
        description: '',
        image: '../../img/food/صb.jpg',
        price: '19',
        calories: '250',
        selected: false,
        size: {
          small: 19,
          large: 26,
       },
      },
      {
        id: 21,
        title: 'ايس كراميل لاتيه',
        description: '',
        image: '../../img/food/صbe.jpg',
        price: 13,
        calories: '206',
        selected: false,
        size: {
          small: 13,
          large: 18,
       },
      },
      {
        id: 22,
        title: 'ايس كراميل مكياتو',
        description: '',
        image: '../../img/food/كراميلمكياتو7.jpg',
        price: 18,
        calories: '170',
        selected: false,
        size: {
          small: 18,
          large: 20,
       },
      },
      {
        id: 23,
        title: 'ايس وايت موكا',
        description: '',
        image: '../../img/food/ص28f.jpg',
        price: 18,
        calories: '212',
        selected: false,
        size: {
          small: 18,
          large: 20,
       },
      },
      {
        id: 24,
        title: 'ايس موكا ',
        description: '',
        image: '../../img/food/f.jpg',
        price: 18,
        calories: '230',
        selected: false,
        size: {
          small: 18,
          large: 20,
       },
      },
      {
        id: 25,
        title: 'ايس كراميل فرابتشينو',
        description: '',
        image: '../../img/food/8e.jpg',
        price: 18,
        calories: '288',
        selected: false,
        size: {
          small: 18,
          large: 20,
       },
      },
      {
        id: 26,
        title: 'ايس امريكانو',
        description: '',
        image: '../../img/food/050.jpg',
        price: 12,
        calories: 15,
        selected: false,
        size: {
          small: 12,
          large: 14,
       },
      },
      {
        id: 27,
        title: 'ايس كولد برو',
        description: '',
        image: '../../img/food/050.jpg',
        price: 18,
        calories: '8',
        selected: false,
        size: {
          small: 18,
          large: 20,
       },
      },
      {
        id: 28,
        title: 'ايس كركدية',
        description: '',
        image: '../../img/food/icekrkr.jpg',
        price: 12,
        calories: '132',
        selected: false,
        size: {
          small: 12,
          large: 15,
       },
      },
      {
        id: 29,
        title: 'ايس تي',
        description: '',
        image: '../../img/food/icetea.jpg',
        price: 12,
        calories: '98',
        selected: false,
        size: {
          small: 12,
          large: 15,
       },
      },
      {
        id: 30,
        title: 'ميلك شيك',
        description: '',
        image: '../../img/food/45.jpg',
        price: 20,
        calories: '354',
        selected: false,
        size: {
          small: 20,
          large: 23,
       },
       extras: [
        { name: 'أوريو', price: 0 },
        { name: 'كيندر', price: 0 },
        { name: 'نوتيلا', price: 0 },
        { name: 'سنكرز', price: 0 },
      ],
      },
    ],
  },
  {
    id: 3,
    title: 'الشاي',
    image: '../../../img/list/tea.png',
    selected: false,
    items: [
      {
        id: 31,
        title: 'شاي مغربي',
        description: '',
        image: '../../img/food/892.jpg',
        price: 15,
        calories: 2,
        selected: true,
      },
      {
        id: 32,
        title: 'شاي احمر',
        description: '',
        image: '../../img/food/892.jpg',
        price: 4,
        calories: 2,
        selected: true,
      },
      {
        id: 33,
        title: 'شاي اخضر',
        description: '',
        image: '../../img/food/892.jpg',
        price: 4,
        calories: 2,
        selected: true,
      },
      {
        id: 71,
        title: 'شاي كركدية',
        description: '',
        image: '../../img/food/3d5.jpg',
        price: 6,
        calories: 5,
        selected: true,
      },
      {
        id: 34,
        title: 'شاي انجليزي',
        description: '',
        image: '../../img/food/892.jpg',
        price: 4,
        calories: 3,
        selected: true,
      },
    ],
  },
  {
    id: 4,
    title: 'الحلا',
    image: '../../../img/list/soit.png',
    selected: false,
    items: [
      {
        id: 35,
        title: 'كريب نوتيلا',
        description: 'الإضافة الواحدة للفواكة بـ 3 ريال',
        image: '../../img/food/وافل.jpg',
        price: 18,
        calories: 518,
        selected: false,
        extras: [
          { name: 'موز', price: 3 },
          { name: 'فراولة', price: 3 },
          { name: 'كيوي', price: 3 },
        ],
      },
      {
        id: 36,
        title: 'كريب مكس',
        description: 'الإضافة الواحدة للفواكة بـ 3 ريال',
        image: '../../img/food/وافل.jpg',
        price: 25,
        calories: 518,
        selected: false,
        extras: [
          { name: 'موز', price: 3 },
          { name: 'فراولة', price: 3 },
          { name: 'كيوي', price: 3 },
        ],
      },
      {
        id: 236,
        title: 'كريب فوتشيني نوتيلا',
        description: 'الإضافة الواحدة للفواكة بـ 3 ريال',
        image: '../../img/food/فوتشيني.jpg',
        price: 18,
        calories: 518,
        selected: false,
        extras: [
          { name: 'موز', price: 3 },
          { name: 'فراولة', price: 3 },
          { name: 'كيوي', price: 3 },
        ],
      },
      {
        id: 136,
        title: 'كريب فوتشيني مكس',
        description: 'الإضافة الواحدة للفواكة بـ 3 ريال',
        image: '../../img/food/فوتشيني.jpg',
        price: 25,
        calories: 518,
        selected: false,
        extras: [
          { name: 'موز', price: 3 },
          { name: 'فراولة', price: 3 },
          { name: 'كيوي', price: 3 },
        ],
      },
      {
        id: 37,
        title: 'ميني بان كيك نوتيلا',
        description: 'الإضافة الواحدة للفواكة بـ 3 ريال',
        image: '../../img/food/بانكيك.jpg',
        price: 14,
        calories: 455,
        selected: true,
        size: {
          small: 14,
          medium: 23,
          large: 46,
        },
        extras: [
          { name: 'موز', price: 3 },
          { name: 'فراولة', price: 3 },
          { name: 'كيوي', price: 3 },
        ],
      },
      {
        id: 38,
        title: 'ميني بان كيك مكس',
        description: 'الإضافة الواحدة للفواكة بـ 3 ريال',
        image: '../../img/food/بانكيك.jpg',
        price: 17,
        calories: 455,
        selected: true,
        size: {
          small: 17,
          medium: 28,
          large: 60,
        },
        extras: [
          { name: 'موز', price: 3 },
          { name: 'فراولة', price: 3 },
          { name: 'كيوي', price: 3 },
        ],
      },
      {
        id: 39,
        title: 'وافل نوتيلا',
        description: 'الإضافة الواحدة بـ 2 ريال',
        image: '../../img/food/وافل2.jpg',
        price: 18,
        calories: 505,
        selected: true,
        extras: [
          { name: 'نوتيلا', price: 2 },
          { name: 'بستاشيو', price: 2 },
          { name: 'لوتس', price: 2 },
        ],
      },
      {
        id: 40,
        title: 'وافل مكس',
        description: '',
        image: '../../img/food/وافل2.jpg',
        price: 24,
        calories: 505,
        selected: true,
      },
      {
        id: 41,
        title: 'كوكيز',
        description: '',
        image: '../../img/food/كوكيز.jpg',
        price: 8,
        calories: 270,
        selected: true,
      },
    ],
  },
  {
    id: 3,
    title: 'المعجنات',
    image: '../../../img/list/croissant.png',
    selected: false,
    items: [
      {
        id: 42,
        title: 'كرسون',
        description: '',
        image: '../../img/food/كرسون.jpg',
        price: 10,
        calories: 240,
        selected: true,
      },
    ],
  },
  {
    id: 5,
    title: 'الموهيتو',
    image: '../../../img/list/moheto5a9.png',
    selected: false,
    items: [
      {
        id: 43,
        title: 'موهيتو كرز',
        description: '',
        image: '../../img/food/max.jpg',
        price: 20,
        calories: 0,
        selected: true,
      },
      {
        id: 44,
        title: 'موهيتو كيوي',
        description: '',
        image: '../../img/food/kiwi.jpg',
        price: 20,
        calories: 0,
        selected: true,
      },
      {
        id: 45,
        title: 'موهيتو بطيخ',
        description: '',
        image: '../../img/food/max.jpg',
        price: 20,
        calories: 0,
        selected: true,
      },
      {
        id: 46,
        title: 'موهيتو رمان',
        description: '',
        image: '../../img/food/max.jpg',
        price: 20,
        calories: 0,
        selected: true,
      },
      {
        id: 47,
        title: 'موهيتو توت احمر',
        description: '',
        image: '../../img/food/max.jpg',
        price: 20,
        calories: 0,
        selected: true,
      },
      {
        id: 48,
        title: 'موهيتو توت اسود',
        description: '',
        image: '../../img/food/totasod.jpg',
        price: 20,
        calories: 0,
        selected: true,
      },
      {
        id: 49,
        title: 'موهيتو فيوليت',
        description: '',
        image: '../../img/food/totasod.jpg',
        price: 20,
        calories: 0,
        selected: true,
      },
      {
        id: 50,
        title: 'موهيتو باشن فروت',
        description: '',
        image: '../../img/food/d03.jpg',
        price: 20,
        calories: 0,
        selected: true,
      },
      {
        id: 51,
        title: 'موهيتو ليمون',
        description: '',
        image: '../../img/food/moh-limon.jpg',
        price: 20,
        calories: 0,
        selected: true,
      },
      {
        id: 52,
        title: 'موهيتو فراولة',
        description: '',
        image: '../../img/food/max.jpg',
        price: 20,
        calories: 0,
        selected: true,
      },
      {
        id: 152,
        title: 'موهيتو بلو كوراساو',
        description: '',
        image: '../../img/food/blo-skar.jpg',
        price: 20,
        calories: 0,
        selected: true,
      },
    ],
  },
  {
    id: 6,
    title: 'المشروبات الغازية',
    image: '../../../img/list/energy.png',
    selected: false,
    items: [
      {
        id: 53,
        title: 'بيبسي',
        description: '',
        image: '../../img/food/pebsi.jpg',
        price: 3,
        calories: 150,
        selected: true,
      },
      {
        id: 54,
        title: 'سفن اب',
        description: '',
        image: '../../img/food/sfn.jpeg',
        price: 3,
        calories: 157,
        selected: true,
      },
      {
        id: 55,
        title: 'ميرندا',
        description: '',
        image: '../../img/food/حمضيات.png',
        price: 3,
        calories: 179,
        selected: true,
      },
      {
        id: 56,
        title: 'مياه',
        description: '',
        image: '../../img/food/ber.png',
        price: 1,
        calories: '0',
        selected: true,
      },
    ],
  },
]
export const deliveryOptions = [
  {
    id: 1,
    component: Transport,
    name: 'توصيل',
    img: '../../img/delivery/delivery.png',
  },
  {
    id: 2,
    component: ReciveFromStore,
    name: 'إستلام',
    img: '../../img/delivery/pickup.png',
  },
  {
    id: 3,
    component: TableNumber,
    name: 'إختيار الطاولة',
    img: '../../img/delivery/number.png',
  },
]
