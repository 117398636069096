const Item = ({ item, setModalOn, modalOn, singleItem, setSingleItem }) => {
  const handleClick = () => {
    setModalOn(true)
    setSingleItem(item)
  }
  return (
    <div
      // to={`/${item.id}`}
      onClick={handleClick}
      className='w-full h-28 rounded-lg inline grid grid-cols-12 gap-2 cursor-pointer bg-gray-100 dark:bg-gray-900'
    >
      <div className='relative w-full rounded-lg col-span-4 sm:col-span-3 flex items-center justify-center'>
        <img
          className='absolute inset-0 w-full h-full p-0.5 object-cover rounded-lg'
          src={item.image}
          alt='item img'
        />
      </div>
      <div className='w-full relative col-span-8 sm:col-span-9 space-y-1 sm:space-y-2 px-2 flex flex-col justify-between'>
        <h1 className='mt-2 text-sm text-primaryGreen-500 font-semibold  dark:text-white'>
          {item.title}
        </h1>
        <p className='text-xs text-gray-800 dark:text-gray-400 overflow-hidden'>
          {item.description}
        </p>
        <div className='flex justify-between py-2 items-center w-full'>
          {item.calories ? (
            <span className='text-xs text-gray-800 dark:text-gray-400 '>
              <span className='text-xs mx-0.5'>سعرات حرارية :</span>
              {item.calories}
            </span>
          ) : (
            ''
          )}

          <span className='text-sm flex items-center font-semibold self-end mr-auto dark:text-white'>
            {item.price.toLocaleString('en-US')}
            <span className='text-primaryGreen-500 dark:text-white text-xs font-semibold mx-0.5'>
              ريال
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Item
