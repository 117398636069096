// import usePWA from 'react-pwa-install-prompt'

const InstallPrompt = () => {
  // const { isStandalone, isInstallPromptSupported, promptInstall } = usePWA()
  // const onClickInstall = async () => {
  //   const didInstall = await promptInstall()
  //   if (didInstall) {
  //     // User accepted PWA install
  //   }
}

// const renderInstallButton = () => {
//     if (isInstallPromptSupported && isStandalone)
//       return (
//         <button onClick={onClickInstall}>Prompt PWA Install</button>
//       )
//     return null
//   }
//   return (<div>
//     {renderInstallButton()}
//   </div>)
// }

export default InstallPrompt
