/*global google*/

import {
  GoogleMap,
  useLoadScript,
  Marker,
  Autocomplete,
} from '@react-google-maps/api'
import { useForm } from 'react-hook-form'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import usePlaceAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'
import { BiSearch, BiTargetLock } from 'react-icons/bi'
import Loader from './Loader'
import marker from '../img/marker2.svg'
import point from '../img/point.png'

const MyGoogleMap = ({ latlng, fixedCenter, setFixedCenter }) => {
  const mapRef = useRef()

  const { register, watch, handleSubmit, setValue: setInputValue } = useForm()

  const {
    value,
    setValue,
    suggestions: { status, data },
  } = usePlaceAutocomplete()

  const onSubmit = (data) => {
    console.log(data)
    setValue(data?.search)
  }

  const handleBoundsChanged = () => {
    const centerLat = mapRef.current?.center?.lat() //get map center
    const centerLng = mapRef.current?.center?.lng() //get map center
    setFixedCenter({ lat: centerLat, lng: centerLng })
  }

  // center will be user location of exist if not restaurant location
  const center = useMemo(
    () => (latlng?.lat ? latlng : { lat: 24.621052, lng: 46.54662 }),
    [latlng]
  )

  const mapStyles = useMemo(
    () => [
      {
        styles: [
          {
            featureType: 'poi.business',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [{ visibility: 'on' }],
      },
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [{ visibility: 'off' }],
      },
    ],
    []
  )
  const options = useMemo(
    () => ({
      styles: mapStyles,
      disableDefaultUI: true,
      zoomControl: true,
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      gestureHandling: 'greedy',
    }),
    [mapStyles]
  )
  const onLoad = useCallback((map) => (mapRef.current = map), [])

  useEffect(() => {
    if (!value) return

    const getResults = async () => {
      const results = await getGeocode({ address: value })
      const { lat, lng } = await getLatLng(results[0])

      console.log('results', results)
      console.log('lat', lat)
      console.log('lng', lng)

      mapRef.current.panTo({ lat, lng })
      setInputValue('search', '')
    }

    getResults()
  }, [value, setInputValue])

  const libraries = useMemo(() => ['places'], [])

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCsE5KDJqjPpbTHsQFqSjnJHclQuCBw8c4',
    libraries,
    language: 'ar',
    region: 'SA',
  })

  if (!isLoaded) return <Loader />
  return (
    <GoogleMap
      center={center}
      mapContainerClassName='w-full h-full fixed top-0'
      zoom={15}
      options={options}
      onLoad={onLoad}
      gestureHandling={{ greedy: true }}
      onBoundsChanged={handleBoundsChanged}
    >
      <Marker
        position={fixedCenter}
        icon={{
          url: marker,
          scaledSize: new google.maps.Size(50, 60),
        }}
        zIndex={2}
      />
      <Marker
        position={latlng}
        icon={{
          url: point,
          scaledSize: new google.maps.Size(15, 15),
        }}
      />
      {/* Search */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='absolute shadow-md z-40 top-4 left-4 flex items-center overflow-hidden justify-center bg-white rounded-full text-gray-700 group'
      >
        <Autocomplete onPlaceChanged={() => console.log(watch('search'))}>
          <input
            type='text'
            dir='ltr'
            autoComplete='off'
            {...register('search')}
            className={`w-0 group-hover:w-40 focus:w-40 active:w-40 transition-all outline-none ${
              watch('search') && 'w-40'
            }`}
          />
        </Autocomplete>
        <button
          type='submit'
          className='w-8 h-8 p-1 shadow-md flex items-center justify-center'
        >
          <BiSearch />
        </button>
      </form>

      {/* Target */}
      <BiTargetLock
        className='absolute z-40 top-48 left-4 w-8 h-8 p-1 flex shadow-md items-center justify-center bg-white rounded-full text-gray-700'
        onClick={() => mapRef.current.panTo(center)}
      />
    </GoogleMap>
  )
}
export default MyGoogleMap
